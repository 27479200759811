import React from "react";
import { Layout } from "antd";
import SiteHeader from "./Header";
import SiteFooter from "./Footer";

const { Content } = Layout;

const MainLayout = ({ children }) => {
  return (
    <Layout>
      <SiteHeader />
      <Content>{children}</Content>
      <SiteFooter />
    </Layout>
  );
};

export default MainLayout;
