import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Col, Row, Layout, Typography, Menu, Anchor } from "antd";
import { AiOutlineMenuFold } from "react-icons/ai";
const { Title } = Typography;
const { Header } = Layout;
const SiteHeader = () => {
  const menuItems = [
    {
      label: <Link to="/"> Home</Link>,
    },
  ];
  return (
    <Header
      style={{
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#F9F4F6",
        zIndex: "2",
        width: "100%",
        height: "4rem",
        margin: 0,
        padding: "0 1rem",
      }}
      className="nav-menu"
    >
      <Col
        xs={23}
        sm={23}
        md={{ span: 12, offset: 4 }}
        lg={{ span: 12, offset: 5 }}
        xl={{ span: 8, offset: 3 }}
      >
        <Title className="logo" style={{ margin: 0 }} level={2}>
          DANNYNOW<span style={{ color: "#F45050" }}>.</span>
        </Title>
      </Col>
      <Col
        xs={1}
        sm={1}
        md={{ span: 12, offset: 0 }}
        lg={{ span: 12, offset: 4 }}
        xl={{ span: 8, offset: 8 }}
        className="nav-items"
      >
        <Anchor
          direction="horizontal"
          items={[
            {
              key: "home",
              href: "#Home",
              title: "Home",
            },
            {
              key: "serivices",
              href: "#Services",
              title: "Services",
            },
            {
              key: "about",
              href: "#About",
              title: "About",
            },
            {
              key: "contact",
              href: "#Contact",
              title: "Contact",
            },
            {
              key: "blog",
              href: "#Blog",
              title: "Blog",
            },
          ]}
        />
      </Col>
      <Col xs={1} sm={1} className="hamberg-menu">
        <AiOutlineMenuFold style={{ fontSize: "25px" }} />
      </Col>
      {/* <Row gutter={{ xs: 16, sm: 16 }} className="sidebar">
        <Col span={24}>
          <a href="/#Home">Home</a>
        </Col>
        <Col span={24}>
          <a href="/#Services">Services</a>
        </Col>
        <Col span={24}>
          <a href="/#About">About</a>
        </Col>
        <Col span={24}>
          <a href="#Contact">Contact</a>
        </Col>
        <a href="#Blog">Blog</a>
      </Row> */}
    </Header>
  );
};

export default SiteHeader;
