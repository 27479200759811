import React from "react";
import { HiOutlineCheckBadge } from "react-icons/hi2";
import { GoTasklist } from "react-icons/go";
import { FaHeadphonesAlt } from "react-icons/fa";
import { RiCodeBoxFill } from "react-icons/ri";
import { MdWorkOutline } from "react-icons/md";
import { VscStarFull, VscStarEmpty } from "react-icons/vsc";
import { CiLinkedin } from "react-icons/ci";
import { TfiEmail } from "react-icons/tfi";
import { TbInfoTriangleFilled } from "react-icons/tb";
import {
  Col,
  Row,
  Image,
  Typography,
  Button,
  Space,
  Descriptions,
  Avatar,
  List,
  Modal,
  Form,
  Input,
  Select,
  notification,
} from "antd";
import dayjs from "dayjs";
import { useState } from "react";

const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;

const Home = () => {
  const [openModal, setOpenModal] = useState(false);
  const [feedbackForm] = Form.useForm();
  const [contactForm] = Form.useForm();

  const handleSubmitFeeback = (formData) => {
    notification.open({
      placement: "topRight",
      message: <Title level={5}>Feedback sent</Title>,
      description: `Hello, ${formData.clientName}, thanks for your feedback.`,
      icon: <TbInfoTriangleFilled style={{ color: "#6603a8" }} />,
    });
    setOpenModal(false);
    feedbackForm.resetFields();
  };
  const handleSubmitEnquiry = (formData) => {
    notification.open({
      placement: "topRight",
      message: <Title level={5}>Message sent</Title>,
      description: `Hello, ${formData.name} thanks for connecting, I will get back to you soon.`,
      icon: <TbInfoTriangleFilled style={{ color: "#6603a8" }} />,
    });

    contactForm.resetFields();
  };
  return (
    <>
      <section id="Home" className="home">
        <Row justify="space-around" align="middle">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12, offset: 0 }}
            xl={12}
            xxl={12}
            align="center"
          >
            <Image src="images/img1.png" preview={false} />
          </Col>
          <Col
            xs={{ span: 23, offset: 0 }}
            sm={{ span: 20, offset: 4 }}
            md={{ span: 19, offset: 5 }}
            lg={{ span: 12, offset: 0 }}
            xl={12}
          >
            <Space direction="vertical" size={20}>
              <Col>
                <Title
                  level={3}
                  type="success"
                  style={{
                    margin: 0,
                  }}
                >
                  Hi, I'm
                </Title>
                <Title
                  style={{
                    margin: 0,
                  }}
                  level={2}
                >
                  DNYANESHWAR VIGHNE
                </Title>
                <Title
                  style={{
                    margin: 0,
                  }}
                  level={4}
                >
                  MERN-STACK DEVELOPER
                </Title>
                <Descriptions
                  style={{
                    width: "100%",
                    maxWidth: "600px",
                    margin: "auto",
                  }}
                >
                  <Descriptions.Item>
                    I am an experienced software developer with over 2 years of
                    expertise as a MERN stack developer. I specialize in
                    designing and maintaining full-stack applications, combining
                    proficiency in both frontend and backend technologies. With
                    a proven track record of leading teams, managing project
                    lifecycles, and solving complex technical challenges, I
                    ensure efficient and impactful project delivery. <br />
                    <br />I have hands-on experience deploying secure and
                    scalable applications using AWS and leveraging AI tools to
                    enhance productivity. My focus is on delivering
                    high-quality, performance-driven solutions tailored to meet
                    business objectives. <br />
                    <br />
                    Let’s build something amazing together!
                  </Descriptions.Item>
                </Descriptions>
              </Col>

              <Space size={25}>
                <a
                  href="/cv/Dnyaneshwar Vighne Resume 18-11-24.pdf"
                  download="Dnyaneshwar's Resume"
                  rel="noreferrer"
                >
                  <Button type="primary" size="large">
                    DOWNLOAD CV
                  </Button>
                </a>

                <Button type="primary" size="large" ghost>
                  <a href="mailto:dnyaneshvighne111@gmail.com">CONTACT</a>
                </Button>
              </Space>
            </Space>
          </Col>
        </Row>
      </section>

      <section id="stats">
        <Row justify="space-around" className="container-stats">
          <Col
            xs={{ span: 21, offset: 3 }}
            sm={{ span: 17, offset: 7 }}
            md={{ span: 6, offset: 0 }}
            lg={6}
            xl={6}
          >
            <Row justify="center">
              <Space size={15}>
                <Col>
                  <Avatar
                    size={64}
                    style={{ backgroundColor: "#43C5CD" }}
                    icon={
                      <HiOutlineCheckBadge
                        fontSize={"50px"}
                        style={{ marginTop: "0.4rem" }}
                      />
                    }
                  ></Avatar>
                </Col>
                <Col>
                  <Title level={4} style={{ margin: 0 }}>
                    2+ Years
                  </Title>
                  <Text className="stat-desc" type="success">
                    Experience
                  </Text>
                </Col>
              </Space>
            </Row>
          </Col>
          <Col
            xs={{ span: 20, offset: 3 }}
            sm={{ span: 15, offset: 7 }}
            md={{ span: 6, offset: 0 }}
            lg={6}
            xl={6}
          >
            <Row justify="center">
              <Space size={15}>
                <Col>
                  <Avatar
                    size={64}
                    style={{ backgroundColor: "#5CBB7C" }}
                    icon={<GoTasklist fontSize={"40px"} />}
                  ></Avatar>
                </Col>
                <Col>
                  <Title level={4} style={{ margin: 0 }}>
                    5+ Projects
                  </Title>
                  <Text className="stat-desc" type="success">
                    Completed
                  </Text>
                </Col>
              </Space>
            </Row>
          </Col>
          <Col
            xs={{ span: 20, offset: 3 }}
            sm={{ span: 15, offset: 7 }}
            md={{ span: 6, offset: 0 }}
            lg={6}
            xl={6}
          >
            <Row justify="center">
              <Space size={15}>
                <Col>
                  <Avatar
                    size={64}
                    style={{ backgroundColor: "#F49550" }}
                    icon={<FaHeadphonesAlt fontSize={"35px"} />}
                  ></Avatar>
                </Col>
                <Col>
                  <Title level={4} style={{ margin: 0 }}>
                    Online 24/7
                  </Title>
                  <Text className="stat-desc" type="success">
                    Support
                  </Text>
                </Col>
              </Space>
            </Row>
          </Col>
        </Row>
      </section>

      <section id="Services" className="services">
        <Row>
          <Col span={24} align="middle" className="heading">
            <Title level={2} style={{ margin: 0, textTransform: "uppercase" }}>
              SERVICES
            </Title>
          </Col>
        </Row>
        <Row
          gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16 }]}
          justify="space-around"
          align="middle"
        >
          <Col
            xs={{ span: 22 }}
            md={{ span: 7, offset: 0 }}
            lg={{ span: 6 }}
            align="middle"
            className="card"
          >
            <Avatar
              size={64}
              style={{ backgroundColor: "#645CBB" }}
              icon={<RiCodeBoxFill fontSize={"35px"} />}
            />
            <Title level={3} style={{ margin: 5 }}>
              Front-end Development
            </Title>
            <Text>
              Crafting visually appealing user interfaces using HTML, CSS, and
              JavaScript for seamless user experiences.
            </Text>
          </Col>
          <Col
            xs={{ span: 22, offset: 0 }}
            md={{ span: 7, offset: 0 }}
            lg={{ span: 6 }}
            align="middle"
            className="card"
          >
            <Avatar
              size={64}
              style={{ backgroundColor: "#D69C07" }}
              icon={<RiCodeBoxFill fontSize={"35px"} />}
            />
            <Title level={3} style={{ margin: 5 }}>
              Website Customization
            </Title>
            <Text>
              Enhancing existing websites with design changes, new features, and
              improved user interactions.
            </Text>
          </Col>
          <Col
            xs={{ span: 22, offset: 0 }}
            md={{ span: 7, offset: 0 }}
            lg={{ span: 6 }}
            align="middle"
            className="card"
          >
            <Avatar
              size={64}
              style={{ backgroundColor: "#CD5E93" }}
              icon={<RiCodeBoxFill fontSize={"35px"} />}
            />
            <Title level={3} style={{ margin: 5 }}>
              CMS Development
            </Title>
            <Text>
              Building custom CMS solutions or customizing platforms like
              WordPress and Drupal for easy content management.
            </Text>
          </Col>
          <Col
            xs={{ span: 22, offset: 0 }}
            md={{ span: 7, offset: 0 }}
            lg={{ span: 6 }}
            align="middle"
            className="card"
          >
            <Avatar
              size={64}
              style={{ backgroundColor: "#5CBB7C" }}
              icon={<RiCodeBoxFill fontSize={"35px"} />}
            />
            <Title level={3} style={{ margin: 5 }}>
              Full-Stack Development
            </Title>
            <Text>
              Creating end-to-end web applications with MERN stack—MongoDB,
              Express.js, React.js, and Node.js.
            </Text>
          </Col>
          <Col
            xs={{ span: 22, offset: 0 }}
            md={{ span: 7, offset: 0 }}
            lg={{ span: 6 }}
            align="middle"
            className="card"
          >
            <Avatar
              size={64}
              style={{ backgroundColor: "#F49550" }}
              icon={<RiCodeBoxFill fontSize={"35px"} />}
            />
            <Title level={3} style={{ margin: 5 }}>
              E-commerce Development
            </Title>
            <Text>
              Developing online stores with secure payment gateways, product
              catalogs, and user-friendly shopping experiences.
            </Text>
          </Col>

          <Col
            xs={{ span: 22, offset: 0 }}
            md={{ span: 7, offset: 0 }}
            lg={{ span: 6 }}
            align="middle"
            className="card"
          >
            <Avatar
              size={64}
              style={{ backgroundColor: "#43C5CD" }}
              icon={<RiCodeBoxFill fontSize={"35px"} />}
            />
            <Title level={3} style={{ margin: 5 }}>
              Web Application Development
            </Title>
            <Text>
              Building scalable and feature-rich web applications tailored to
              meet specific business requirements.
            </Text>
          </Col>

          <Col
            xs={{ span: 22, offset: 0 }}
            md={{ span: 7, offset: 0 }}
            lg={{ span: 6 }}
            align="middle"
            className="card"
          >
            <Avatar
              size={64}
              style={{ backgroundColor: "#A2DB00" }}
              icon={<RiCodeBoxFill fontSize={"35px"} />}
            />
            <Title level={3} style={{ margin: 5 }}>
              Website Performance Optimization
            </Title>
            <Text>
              Improving website speed, responsiveness, and performance for
              enhanced user satisfaction and engagement.
            </Text>
          </Col>
        </Row>
      </section>

      <section id="About" className="about">
        <section className="experince-area">
          <Row>
            <Col span={24} align="middle">
              <Title
                level={3}
                type="success"
                style={{ margin: 0, textTransform: "uppercase" }}
                className="sub-heading"
              >
                Why Choose Me
              </Title>
              <Title
                level={2}
                style={{ margin: 0, textTransform: "uppercase" }}
                className="heading"
              >
                MY Experience Area
              </Title>
            </Col>
          </Row>
          <List
            className="experince-list"
            dataSource={[
              {
                title: "Expertise",
                description:
                  "Possess in-depth knowledge and experience in HTML, CSS, JavaScript, PHP, and the MERN stack.",
              },
              {
                title: "Versatility",
                description:
                  "Skilled in handling diverse aspects of web development, including front-end, back-end, customization, CMS development, and web applications.",
              },
              {
                title: "Customization",
                description:
                  "Ability to tailor websites and applications to meet specific client requirements, ensuring unique and personalized solutions.",
              },
              {
                title: "Attention to Detail",
                description:
                  "Meticulous approach to deliver high-quality work with a keen eye for detail, resulting in polished and professional outcomes.",
              },
              {
                title: "Effective Communication",
                description:
                  "Strong communication skills to understand client needs, collaborate effectively, and provide timely updates and support.",
              },
              {
                title: "Problem-Solving Skills",
                description:
                  "Proficient in identifying and resolving complex challenges in web development, ensuring efficient and effective solutions for clients.",
              },
              {
                title: "Client Satisfaction",
                description:
                  "Proven track record of delivering exceptional results and ensuring client satisfaction, backed by positive feedback and testimonials.",
              },
              {
                title: "Timeliness",
                description:
                  "Reliable in meeting project deadlines and delivering on time, ensuring client projects are completed in a timely manner.",
              },
              {
                title: "Professionalism",
                description:
                  "Committed to maintaining a professional approach, reliability, and open communication throughout the development process.",
              },
            ]}
            itemLayout="horizontal"
            size="small"
            grid={{ gutter: 24, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 3 }}
            renderItem={(item, index) => (
              <List.Item>
                <Col>
                  <List.Item.Meta
                    title={
                      <Title
                        level={4}
                        className="sub-heading"
                        style={{ margin: 0, textTransform: "uppercase" }}
                      >
                        {index + 1 + ". " + item.title}
                      </Title>
                    }
                    description={item.description}
                  />
                </Col>
              </List.Item>
            )}
          />
        </section>

        <section className="work-area">
          <Row className="work-header">
            <Col span={24} align="middle">
              <Title
                level={3}
                type="success"
                style={{ margin: 0, textTransform: "uppercase" }}
                className="sub-heading"
              >
                Portfolio
              </Title>
              <Title
                level={2}
                style={{ margin: 0, textTransform: "uppercase" }}
                className="heading"
              >
                My AMAZING WORKS
              </Title>
            </Col>
          </Row>
          <Row
            gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16 }]}
            justify="space-around"
            align="middle"
          >
            <Col
              xs={{ span: 22 }}
              md={{ span: 7, offset: 0 }}
              lg={{ span: 6 }}
              align="middle"
              className="card"
            >
              <Avatar
                size={64}
                style={{ backgroundColor: "#5CBB7C" }}
                icon={<MdWorkOutline fontSize={"35px"} />}
              />
              <Title level={3} style={{ margin: 5 }}>
                Front-end Development
              </Title>
              <Text>
                Crafting visually appealing user interfaces using HTML, CSS, and
                JavaScript for seamless user experiences.
              </Text>
            </Col>
            <Col
              xs={{ span: 22 }}
              md={{ span: 7, offset: 0 }}
              lg={{ span: 6 }}
              align="middle"
              className="card"
            >
              <Avatar
                size={64}
                style={{ backgroundColor: "#5CBB7C" }}
                icon={<MdWorkOutline fontSize={"35px"} />}
              />
              <Title level={3} style={{ margin: 5 }}>
                Front-end Development
              </Title>
              <Text>
                Crafting visually appealing user interfaces using HTML, CSS, and
                JavaScript for seamless user experiences.
              </Text>
            </Col>
            <Col
              xs={{ span: 22 }}
              md={{ span: 7, offset: 0 }}
              lg={{ span: 6 }}
              align="middle"
              className="card"
            >
              <Avatar
                size={64}
                style={{ backgroundColor: "#5CBB7C" }}
                icon={<MdWorkOutline fontSize={"35px"} />}
              />
              <Title level={3} style={{ margin: 5 }}>
                Front-end Development
              </Title>
              <Text>
                Crafting visually appealing user interfaces using HTML, CSS, and
                JavaScript for seamless user experiences.
              </Text>
            </Col>
          </Row>
        </section>

        <section id="Reviews" className="reviews">
          <Row>
            <Col span={24} align="middle">
              <Title
                className="sub-heading"
                level={3}
                style={{ margin: 0, textTransform: "uppercase" }}
                type="success"
              >
                Client’s
              </Title>
              <Title
                className="heading"
                level={2}
                style={{ margin: 0, textTransform: "uppercase" }}
              >
                FEEDBACKS
              </Title>
            </Col>
          </Row>
          <Row
            gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16 }]}
            justify="space-around"
            align="middle"
          >
            <Col
              xs={{ span: 22 }}
              md={{ span: 7, offset: 0 }}
              lg={{ span: 6 }}
              className="review-card"
              align="start"
            >
              <Row justify="end" className="date">
                <Text>{dayjs(Date.now()).format("DD/MM/YYYY")}</Text>
              </Row>
              <Row align="middle" gutter={24}>
                <Avatar
                  size={60}
                  style={{ backgroundColor: "#645CBB" }}
                  src="avtars/woman.png"
                />
                <Col>
                  <Title level={5} style={{ margin: 0, lineHeight: 1.2 }}>
                    Sumedha Jagatap
                  </Title>
                  <Paragraph
                    type="success"
                    style={{ margin: 0, lineHeight: 1.2 }}
                  >
                    Business Analyst
                  </Paragraph>
                </Col>
              </Row>
              <Row className="rating">
                <VscStarFull style={{ fontSize: "25px", color: "#F49550" }} />
                <VscStarFull style={{ fontSize: "25px", color: "#F49550" }} />
                <VscStarFull style={{ fontSize: "25px", color: "#F49550" }} />
                <VscStarFull style={{ fontSize: "25px", color: "#F49550" }} />
                <VscStarEmpty style={{ fontSize: "25px", color: "#F49550" }} />
              </Row>
              <Text>
                Excellent web developer! The website they created exceeded my
                expectations in terms of design, functionality, and performance.
              </Text>
            </Col>
            <Col
              xs={{ span: 22 }}
              md={{ span: 7, offset: 0 }}
              lg={{ span: 6 }}
              className="review-card"
              align="start"
            >
              <Row justify="end" className="date">
                <Text>{dayjs(Date.now()).format("DD/MM/YYYY")}</Text>
              </Row>
              <Row align="middle" gutter={24}>
                <Avatar
                  size={60}
                  style={{ backgroundColor: "#645CBB" }}
                  src="avtars/man.png"
                />
                <Col>
                  <Title level={5} style={{ margin: 0, lineHeight: 1.2 }}>
                    Ganesh More
                  </Title>
                  <Paragraph
                    type="success"
                    style={{ margin: 0, lineHeight: 1.2 }}
                  >
                    Chemical Engineer
                  </Paragraph>
                </Col>
              </Row>
              <Row className="rating">
                <VscStarFull style={{ fontSize: "25px", color: "#F49550" }} />
                <VscStarFull style={{ fontSize: "25px", color: "#F49550" }} />
                <VscStarFull style={{ fontSize: "25px", color: "#F49550" }} />
                <VscStarFull style={{ fontSize: "25px", color: "#F49550" }} />
                <VscStarEmpty style={{ fontSize: "25px", color: "#F49550" }} />
              </Row>
              <Text>
                Highly skilled and reliable. They delivered a customized web
                solution that perfectly matched our requirements.
              </Text>
            </Col>
            <Col
              xs={{ span: 22 }}
              md={{ span: 7, offset: 0 }}
              lg={{ span: 6 }}
              className="review-card"
              align="start"
            >
              <Row justify="end" className="date">
                <Text>{dayjs(Date.now()).format("DD/MM/YYYY")}</Text>
              </Row>
              <Row align="middle" gutter={24}>
                <Avatar
                  size={60}
                  style={{ backgroundColor: "#645CBB" }}
                  src="avtars/woman.png"
                />
                <Col>
                  <Title level={5} style={{ margin: 0, lineHeight: 1.2 }}>
                    Priya Chavan
                  </Title>
                  <Paragraph
                    type="success"
                    style={{ margin: 0, lineHeight: 1.2 }}
                  >
                    Business Analyst
                  </Paragraph>
                </Col>
              </Row>
              <Row className="rating">
                <VscStarFull style={{ fontSize: "25px", color: "#F49550" }} />
                <VscStarFull style={{ fontSize: "25px", color: "#F49550" }} />
                <VscStarFull style={{ fontSize: "25px", color: "#F49550" }} />
                <VscStarFull style={{ fontSize: "25px", color: "#F49550" }} />
                <VscStarEmpty style={{ fontSize: "25px", color: "#F49550" }} />
              </Row>
              <Text>
                Outstanding work! Their expertise in web development resulted in
                a visually stunning and user-friendly website.
              </Text>
            </Col>
          </Row>
          <Row justify="center" className="reviewButton">
            <Button
              style={{ textTransform: "uppercase" }}
              type="primary"
              size="large"
              ghost
              onClick={() => {
                setOpenModal(!openModal);
              }}
            >
              give feedback
            </Button>
          </Row>
          <Modal
            open={openModal}
            title="Feedback"
            onCancel={() => {
              setOpenModal(!openModal);
              feedbackForm.resetFields();
            }}
            okText="Submit"
            onOk={() => {
              feedbackForm.submit();
            }}
            okButtonProps={{ size: "large", style: { borderRadius: 5 } }}
            cancelButtonProps={{ size: "large", style: { borderRadius: 5 } }}
          >
            <Form
              form={feedbackForm}
              name="feedbackForm"
              layout="vertical"
              onFinish={handleSubmitFeeback}
              scrollToFirstError
            >
              <Form.Item
                label="Name"
                name="clientName"
                rules={[
                  {
                    required: true,
                    message: "Name is required please enter your name",
                  },
                  {
                    pattern: /^[A-Za-z\s]{3,30}$/,
                    message:
                      "Name must be between 3 and 30 characters long and can only contain letters and spaces.",
                  },
                ]}
              >
                <Input type="text" placeholder="e.g. Dnyanesh Vighne" />
              </Form.Item>
              <Form.Item
                label="Gender"
                name="clientGender"
                rules={[
                  {
                    required: true,
                    message: "Genderis required please select your gender.",
                  },
                ]}
              >
                <Select
                  placeholder="Select gender"
                  style={{ width: "100%" }}
                  options={[
                    { label: "male", value: "Male" },
                    { label: "Female", value: "female" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Profession"
                name="clientProfession"
                rules={[
                  {
                    required: true,
                    message:
                      "Profession is required please enter your profession",
                  },
                  {
                    pattern: /^[A-Za-z\s]{5,30}$/,
                    message:
                      "Profession must be between 5 and 30 characters long and can only contain letters and spaces.",
                  },
                ]}
              >
                <Input type="text" placeholder="e.g. Web Developer" />
              </Form.Item>
              <Form.Item
                label="Your Feedback"
                name="clientFeedback"
                rules={[
                  {
                    required: true,
                    message:
                      "Feedback is required please enter your feedback message",
                  },
                  {
                    pattern: /^[A-Za-z\s]{60,120}$/,
                    message:
                      "Feedback must be between 60 and 120 characters long and can only contain letters and spaces.",
                  },
                ]}
              >
                <TextArea
                  rows={3}
                  showCount
                  type="text"
                  placeholder="e.g. Excellent web developer! The website they created exceeded my expectations in terms of design, functionality, and performance."
                />
              </Form.Item>
            </Form>
          </Modal>
        </section>
      </section>

      <section id="Blog" className="blogs">
        <Row className="blog-title">
          <Col span={24} align="middle">
            <Title
              className="sub-heading"
              level={3}
              style={{ margin: 0, textTransform: "uppercase" }}
              type="success"
            >
              Blogs{" "}
            </Title>
            <Title
              className="heading blog-heading"
              level={2}
              style={{ margin: 0, textTransform: "uppercase" }}
            >
              Recent Updates, Blog, Tips, Tricks & More
            </Title>
            <Title
              className="blog-heading2"
              level={2}
              style={{ margin: 0, textTransform: "uppercase" }}
            >
              Recent Updates
            </Title>
          </Col>
        </Row>
        <Row
          gutter={[8, { xs: 8, sm: 16, md: 16, lg: 16 }]}
          justify="space-around"
          align="middle"
        >
          <Col
            xs={{ span: 22 }}
            md={{ span: 7, offset: 0 }}
            lg={{ span: 6 }}
            className="blog-card"
            align="start"
          >
            <Image src="images/blog.jpg" preview={false} />
            <Title level={5} style={{ margin: 0 }}>
              Font-End Developer Roadmap
            </Title>

            <Paragraph ellipsis={{ rows: 3, suffix: <a> More</a> }}>
              Excellent web developer! The website they created exceeded my
              expectations in terms of design, functionality, and performance.
            </Paragraph>
            <Row justify="end">
              <Text className="blog-date">
                {dayjs(Date.now()).format("DD/MM/YYYY")}
              </Text>
            </Row>
          </Col>
          <Col
            xs={{ span: 22 }}
            md={{ span: 7, offset: 0 }}
            lg={{ span: 6 }}
            className="blog-card"
            align="start"
          >
            <Image src="images/blog.jpg" preview={false} />
            <Title level={5} style={{ margin: 0 }}>
              Font-End Developer Roadmap
            </Title>

            <Paragraph ellipsis={{ rows: 3, suffix: <a> More</a> }}>
              Excellent web developer! The website they created exceeded my
              expectations in terms of design, functionality, and performance.
            </Paragraph>
            <Row justify="end">
              <Text className="blog-date">
                {dayjs(Date.now()).format("DD/MM/YYYY")}
              </Text>
            </Row>
          </Col>
          <Col
            xs={{ span: 22 }}
            md={{ span: 7, offset: 0 }}
            lg={{ span: 6 }}
            className="blog-card"
            align="start"
          >
            <Image src="images/blog.jpg" preview={false} />
            <Title level={5} style={{ margin: 0 }}>
              Font-End Developer Roadmap
            </Title>

            <Paragraph ellipsis={{ rows: 3, suffix: <a> More</a> }}>
              Excellent web developer! The website they created exceeded my
              expectations in terms of design, functionality, and performance.
            </Paragraph>
            <Row justify="end">
              <Text className="blog-date">
                {dayjs(Date.now()).format("DD/MM/YYYY")}
              </Text>
            </Row>
          </Col>
        </Row>
      </section>

      <section id="Contact" className="contact">
        <Row gutter={24} className="blog-title" justify="space-around">
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} align="start">
            <Title
              className="heading"
              level={2}
              style={{ margin: 0, textTransform: "capitalize" }}
            >
              Let's Connect
            </Title>
            <Paragraph>
              Please fill out the form on this section to contact with me. Or
              call between 9:00 a.m. and 8:00 p.m. ET, Monday through Friday
            </Paragraph>
            <Space>
              <a href="https://www.linkedin.com/in/dnyanex" target="blank">
                <CiLinkedin style={{ fontSize: "32px", color: "#7E7E7E" }} />
              </a>
              <a href="mailto:dnyaneshvighne111@gmail.com">
                <TfiEmail
                  style={{
                    fontSize: "29px",
                    color: "#7E7E7E",
                  }}
                />
              </a>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form
              form={contactForm}
              name="contactForm"
              layout="vertical"
              onFinish={handleSubmitEnquiry}
              scrollToFirstError
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Name is required, please enter your name",
                  },
                  {
                    pattern: /^[A-Za-z\s]{3,30}$/,
                    message:
                      "Name must be between 3 and 30 characters long and can only contain letters and spaces.",
                  },
                ]}
              >
                <Input type="text" placeholder="e.g. Dnyanesh Vighne" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Email is required, please enter your email",
                  },
                  {
                    pattern: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
                    message: "Please inter valid email.",
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder="e.g. dyaneshvighne111@gmail.com"
                />
              </Form.Item>
              <Form.Item
                name="subject"
                label="Subject"
                rules={[
                  {
                    required: true,
                    message: "Subject is required, please enter your subject",
                  },
                  {
                    pattern: /^[A-Za-z\s]{40,80}$/,
                    message:
                      "Subject must be between 40 to 80 characters long and can only contain letters and spaces.",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="e.g. New Feature Request: Integration with Developer Portal"
                />
              </Form.Item>
              <Form.Item name="message" label="Message(Optional)">
                <TextArea
                  rows={4}
                  showCount
                  type="text"
                  placeholder="Message"
                />
              </Form.Item>
              <Form.Item style={{ float: "right" }}>
                <Space>
                  <Button
                    type="primary"
                    size="large"
                    ghost
                    style={{ textTransform: "uppercase" }}
                    onClick={() => {
                      contactForm.resetFields();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    style={{ textTransform: "uppercase" }}
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Home;
