import { Layout, Typography } from "antd";
import React from "react";
const { Footer } = Layout;

const { Text } = Typography;

const SiteFooter = () => {
  return (
    <Footer className="footer">
      <Text>
        Made with love by <Text type="success">dannynow</Text> all rights
        reserved @2023
      </Text>
    </Footer>
  );
};

export default SiteFooter;
