import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Button, ConfigProvider } from "antd";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Poppins, sans-serif",
            colorTextHeading: "#3C486B",
            colorSuccess: "#F45050",
            colorText: "#7E7E7E",
            colorLink: "#3C486B",
            colorLinkActive: "#F45050",
            colorLinkHover: "#F45050",
          },
          components: {
            Button: {
              colorPrimary: "#F45050",
              borderRadius: 25,
              borderRadiusLG: 50,
            },
          },
        }}
      >
        <App />
      </ConfigProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
